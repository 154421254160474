import React from "react";
import { MdRequestQuote } from "react-icons/md";
import { Link } from "react-scroll";
// import Gallery13 from "../../images/gallery13.jpg";
// import Gallery1 from "../../images/gallery4.jpeg";
import Gallery1 from "../../images/gallery6.jpg";
import Gallery2 from "../../images/gallery7.jpg";
import Gallery3 from "../../images/gallery17.jpg";
import Gallery4 from "../../images/gallery1.jpg";

const Hero = () => {
  const heroImg = [Gallery1, Gallery2, Gallery3, Gallery4];

  return (
    <section
      className="hero bg-background text-center rounded-[12px] p-[2.4rem]"
      id="home"
    >
      <div className="hero-wrapper  mx-auto max-w-[100rem] mt-[2.4rem] flex flex-col justify-center items-center">
        <div className="hero__content">
          <h1 className="font-montserrat font-extrabold text-heading-text text-[4.4rem] xl:text-[5.2rem] mb-[2.4rem]">
            Elevate Your Living Experience with <br></br>Seven-Star Elevators
          </h1>
          <p className="font-opensans font-normal text-body-text text-[1.8rem] mb-[3.6rem] leading-relaxed">
            Seven-Star Elevators is a leading provider of innovative vacuum
            elevator solutions. We redefine vertical mobility with our
            space-saving, energy-efficient designs, ensuring secure and
            comfortable rides for a luxurious and elevated living experience.
          </p>

          <div className="hero__CTA flex items-center justify-center flex-col md:flex-row gap-[2.4rem] mb-[6.4rem]">
            <button className="custom-btn ">
              <Link
                to="product"
                smooth={true}
                offset={-96}
                duration={500}
                className="text-[1.8rem]"
              >
                Discover more
              </Link>
            </button>
            <button className="flex items-center justify-center gap-[0.4rem]">
              <MdRequestQuote className="text-[1.8rem] text-heading-text hover:shadow-md shadow-blue-50 transition-all duration-300 ease-in-out" />
              <Link
                to="contact"
                smooth={true}
                offset={-96}
                duration={500}
                className="font-opensans text-heading-text text-[1.6rem] font-bold"
              >
                Request a Quote
              </Link>
            </button>
          </div>
        </div>
        <div className="hero__img grid grid-cols-2 md:grid-cols-4 gap-[0.8rem] md:h-[44rem]">
          {heroImg.map((img, index) => (
            <img
              key={img}
              src={img}
              alt="vaccum-elevators"
              className={`rounded-[12px] w-full h-full object-cover shadow-md  ${
                (index + 1) % 2 === 0 ? "mt-[4.8rem]" : ""
              }`}
            />
          ))}
        </div>
      </div>{" "}
    </section>
  );
};

export default Hero;
